import Loading from "components/pages/loading";
import usePagination, {usePaginationContext} from "lib/hooks/use-pagination";
import Navigation from "./navigation";
import Search from "./search";
import Table from "./table";

export default function Container() {
  const {loading} = usePagination();
  const {search} = usePaginationContext();
  if(loading) { return <Loading />; }

  return (
    <div>
      <div className="gap-2 flex flex-col md:flex-row justify-between my-2">
        {search ? <Search /> : <div />}
        <Navigation />
      </div>
      <Table />
      <div className="gap-2 flex flex-col md:flex-row justify-end my-2">
        <Navigation />
      </div>
    </div>
  );
}
