import PropTypes from "prop-types";
import {useState} from "react";
import Content from "components/helpers/action-text/content";
import Editor from "components/helpers/action-text/editor";

function ContentEditor({id, name, onChange, value}) {
  const [content, setContent] = useState(value);
  const [editContent, setEditContent] = useState(false);
  const [preview, setPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  return (
    <div className="max-w-screen-md mx-auto md:mx-[-50%] md:w-screen">
      <div className="flex">
        <textarea
          className="flex-auto rounded-r-none"
          disabled={true}
          id={`${id}-disabled`}
          name={`${name}-disabled`}
          placeholder="Preview"
          rows={4}
          value={content || ""}
        />
        <div className="flex flex-none">
          <button
            className="btn btn-green btn-sm rounded-none"
            onClick={() => setShowPreview(!showPreview)}
            type="button"
          >
            {showPreview ? "Hide Preview" : "Preview"}
          </button>
          <button
            className="btn btn-gray btn-sm rounded-l-none"
            onClick={() => setEditContent(!editContent)}
            type="button"
          >
            {editContent ? "Hide" : "Edit"}
          </button>
        </div>
      </div>
      {showPreview && (
        <div className="custom-html mt-3">
          <Content className="trix-content" html={preview} />
        </div>
      )}
      <Editor
        className={editContent ? "mt-3" : "hidden"}
        id={id}
        name={name}
        onChange={onChange}
        onPreviewChange={setPreview}
        onTextChange={setContent}
        value={value}
      />
    </div>
  );
}

ContentEditor.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ContentEditor;
