import Loading from "components/pages/loading";
import {useEdit as useForm} from "lib/hooks/use-form";
import Form from "./form";

export default function Edit() {
  const {onChange, onSubmit, record, value} = useForm({url: "/api/admin/pages/:id"});
  if(!record) { return <Loading />; }

  return (
    <>
      <h1 className="text-center">Edit Page</h1>
      <Form onChange={onChange} onSubmit={onSubmit} value={value} />
    </>
  );
}
