import * as ActiveStorage from "@rails/activestorage";
import "@rails/actiontext";
import Rails from "@rails/ujs";
import {createRoot} from "react-dom/client";
import "trix";
import "whatwg-fetch";
import Application from "components/application";
import "app/assets/style.external.scss";
import "app/assets/style.scss";

Rails.start();
ActiveStorage.start();

document.addEventListener("DOMContentLoaded", () => {
  const appProps = document.querySelector("#app-props");
  const node = document.querySelector("#app");
  const props = JSON.parse(appProps.getAttribute("data"));
  const root = createRoot(node);

  root.render(<Application {...props} />);
});
