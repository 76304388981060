import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {toQueryString} from "lib/ext/object";
import usePagination from "lib/hooks/use-pagination";

function Button({disabled = false, icon, to}) {
  const {query} = usePagination();

  if(disabled) {
    return (
      <button className="btn btn-gray btn-disabled" type="button">
        <i className={`fas ${icon}`} />
      </button>
    );
  }

  return (
    <Link className="btn btn-gray" to={`?${toQueryString({...query, page: to})}`}>
      <i className={`fas ${icon}`} />
    </Link>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  to: PropTypes.number.isRequired
};

export default function Navigation() {
  const {number, pages} = usePagination();

  return (
    <div className="btn-group">
      <Button disabled={number <= 1} icon="fa-angle-double-left" to={1} />
      <Button disabled={number <= 1} icon="fa-angle-left" to={number - 1} />
      <button className="btn btn-gray btn-disabled" type="button">{number}</button>
      <Button disabled={number >= pages} icon="fa-angle-right" to={number + 1} />
      <Button disabled={number >= pages} icon="fa-angle-double-right" to={pages} />
    </div>
  );
}
