import FormWithFiles from "components/helpers/form/with-files";
import Loading from "components/pages/loading";
import {useEdit as useForm} from "lib/hooks/use-form";
import Content from "./content";

const mapSubmit = ({changes, files}) => {
  const {contents, ...updates} = changes;

  updates.contents_attributes = contents.map((record) => (
    files[record.id] ? {...record, value: files[record.id]} : record
  ));

  return updates;
};

export default function Edit() {
  const {onChanges, onSubmit, record, value} = useForm({mapSubmit, withFiles: true, url: "/api/admin/sections/:id"});
  if(!record) { return <Loading />; }

  const onChange = (updatedContent) => (
    onChanges((changes) => {
      const contents = (changes.contents || record.contents).map((content) => (
        content.id === updatedContent.id ? updatedContent : content
      ));

      return {...changes, contents};
    })
  );

  return (
    <div className="max-w-sm mx-auto">
      <FormWithFiles onSubmit={onSubmit}>
        <h1 className="text-center">Edit Content</h1>
        <h2 className="text-center">{record.name}</h2>
        {value("contents", []).map((content) => (
          <Content key={content.id} onChange={onChange} record={content} />
        ))}
        <div className="text-center">
          <button className="btn btn-blue" type="submit">Submit</button>
        </div>
      </FormWithFiles>
    </div>
  );
}
