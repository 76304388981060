import PropTypes from "prop-types";
import {NavLink as Link} from "react-router-dom";

function NavLink({active = false, className = "", ...props}) {
  const classes = ({isActive}) => [className, (active || isActive) && "active", "btn-nav"].filter(Boolean).join(" ");

  return <Link className={classes} {...props} />;
}

NavLink.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string
};

export default NavLink;
