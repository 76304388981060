import {useState} from "react";
import {Link} from "react-router-dom";
import useSWRMutation from "swr/mutation";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import useAppContext from "lib/hooks/use-app-context";

const url = "/api/public/session";

export default function Session() {
  const {dispatch} = useAppContext();
  const [user, setChanges] = useState({email: "", password: ""});
  const {trigger} = useSWRMutation({method: "POST", url});

  const onChange = ({target: {name, value}}) => (
    setChanges({...user, [name]: value})
  );
  const onSubmit = (e) => {
    e.preventDefault();

    trigger({params: {record: user}}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));

      if(data.success) { dispatch(setUser(data.record)); }
    });
  };

  return (
    <div className="container">
      <h1 className="text-center">Log In</h1>
      <div className="max-w-sm mx-auto">
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <input
              id="session-email"
              name="email"
              onChange={onChange}
              placeholder="Email"
              required={true}
              type="email"
              value={user.email}
            />
          </div>
          <div className="mb-4">
            <input
              id="session-password"
              name="password"
              onChange={onChange}
              placeholder="Password"
              required={true}
              type="password"
              value={user.password}
            />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-theme mr-2">Sign In</button>
            <Link className="btn btn-blue" to={`/register?email=${user.email}`}>Sign Up</Link>
          </div>
          <div className="mt-2 text-center">
            <Link className="btn btn-link" to={`/forgot-password?email=${user.email}`}>Forgot Password?</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
