import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Check from "components/helpers/check";
import {displayDateTime} from "lib/ext/string";
import {useDelete} from "lib/hooks/use-pagination";

function AdminUsersListRow({record}) {
  const onDelete = useDelete({check: "Are you sure you want to delete this user?", record});

  return (
    <tr>
      <td><Check checked={record.admin} /></td>
      <td>{record.email}</td>
      <td>{[record.first_name, record.last_name].filter(Boolean).join(" ")}</td>
      <td>{displayDateTime(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <Link className="btn btn-sm btn-blue" to={`/admin/users/${record.id}`}>Edit</Link>
          <button type="button" className="btn btn-sm btn-red" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminUsersListRow.propTypes = {
  record: PropTypes.shape({
    admin: PropTypes.bool.isRequired,
    created_at: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    last_name: PropTypes.string.isRequired
  }).isRequired
};

export default AdminUsersListRow;
