import {useEffect} from "react";

export default function useScrollbarWidth() {
  useEffect(() => {
    const updateWidth = () => {
      const width = window.innerWidth - document.documentElement.clientWidth;

      document.documentElement.style.setProperty("--scrollbar-width", `${width}px`);
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);
}
