export function dig(object, ...keys) {
  const reducer = (result, key) => (result && result[key] != null ? result[key] : null);

  return keys.reduce(reducer, object);
}

export function slice(object, properties) {
  return properties.reduce((newObject, key) => (
    {...newObject, [key]: object[key]}
  ), {});
}

export function toQueryString(_object) {
  const keys = Object.keys(_object).filter((key) => _object[key] != null);
  const object = slice(_object, keys);

  return new URLSearchParams(object).toString();
}

export function withoutBlankValues(_object) {
  const object = {..._object};

  Object.keys(object).forEach((key) => {
    const value = object[key];

    if(value == null || value === "") { return delete object[key]; }
    if(typeof value === "object" && value.length === 0) { delete object[key]; }
  });

  return object;
}
