import {useLocation} from "react-router-dom";
import NavLink from "components/helpers/nav-link";

function SettingsNavbar() {
  return (
    <div className="bg-dark bg-darkGray">
      <div className="container py-2">
        <div className="flex items-center justify-between">
          <NavLink to="/admin/settings">Settings</NavLink>
          <div className="flex flex-wrap gap-4 justify-end">
            <NavLink to="/admin/sections">Content</NavLink>
            <NavLink to="/admin/pages">Pages</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function AdminNavbar() {
  const {pathname} = useLocation();
  const settings = ["/admin/pages", "/admin/sections"]
    .some((path) => pathname.startsWith(path));

  return (
    <>
      <nav className="bg-dark bg-green">
        <div className="container py-2">
          <div className="flex items-center justify-between">
            <NavLink to="/admin">Admin</NavLink>
            <div className="flex flex-wrap gap-4 justify-end">
              <NavLink active={settings} to="/admin/settings">Settings</NavLink>
              <NavLink to="/admin/users">Users</NavLink>
            </div>
          </div>
        </div>
      </nav>
      {settings && <SettingsNavbar />}
    </>
  );
}
