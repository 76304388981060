import {createBrowserRouter, redirect, Navigate} from "react-router-dom";
import Layout from "components/layout";
import ErrorBoundary from "components/layout/router-error-boundary";
import * as Pages from "components/pages";

export default function createRouter(initialState) {
  const {pages, user} = initialState;
  const requireAdmin = () => {
    if(!user) return redirect("/session");
    if(!user.admin) return redirect("/profile");

    return null;
  };
  const requireNoUser = () => {
    if(user) return redirect("/profile");

    return null;
  };
  const requireUser = () => {
    if(!user) return redirect("/session");

    return null;
  };
  const staticPages = pages.map(({path}) => (
    {Component: Pages.Public.Template, path: `/${path}`}
  ));

  return createBrowserRouter(
    [{
      children: [
        {Component: Pages.Public.Template, path: "/"},
        ...[
          {Component: Pages.Public.User.Profile, loader: requireUser, path: "/profile"},
          {Component: Pages.Public.User.ForgotPassword, loader: requireNoUser, path: "/forgot-password"},
          {Component: Pages.Public.User.Notifications, loader: requireUser, path: "/notifications"},
          {Component: Pages.Public.User.Register, loader: requireNoUser, path: "/register"},
          {Component: Pages.Public.User.Session, loader: requireNoUser, path: "/session"}
        ].map((route) => ({
          children: [route],
          path: route.path,
          Component: Pages.Public.User.Container
        })),
        {element: <Navigate to="/" />, path: "/home"},
        {element: <Navigate to="/admin/sections" />, loader: requireAdmin, path: "/admin"},
        {element: <Navigate to="/admin/sections" />, loader: requireAdmin, path: "/admin/settings"},
        {
          children: [
            {Component: Pages.Admin.Settings.Pages.New, loader: requireAdmin, path: "/admin/pages/new"},
            {Component: Pages.Admin.Settings.Pages.Edit, loader: requireAdmin, path: "/admin/pages/:id"},
            {Component: Pages.Admin.Settings.Pages.List, loader: requireAdmin, path: "/admin/pages"}
          ],
          path: "/admin/pages",
          Component: Pages.Admin.Settings.Container
        },
        {
          children: [
            {Component: Pages.Admin.Settings.Sections.Edit, loader: requireAdmin, path: "/admin/sections/:id"},
            {Component: Pages.Admin.Settings.Sections.List, loader: requireAdmin, path: "/admin/sections"}
          ],
          path: "/admin/sections",
          Component: Pages.Admin.Settings.Container
        },
        {Component: Pages.Admin.Users.New, loader: requireAdmin, path: "/admin/users/new"},
        {Component: Pages.Admin.Users.Edit, loader: requireAdmin, path: "/admin/users/:id"},
        {Component: Pages.Admin.Users.List, loader: requireAdmin, path: "/admin/users"},
        ...staticPages,
        {Component: Pages.NotFound}
      ],
      Component: Layout,
      ErrorBoundary
    }]
  );
}
