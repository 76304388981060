import {toQueryString} from "lib/ext/object";
import headers from "./headers";

const request = ({params, url, ...options}) => (
  fetch(`${url}?${toQueryString(params || {})}`, {
    headers: headers(),
    method: "GET",
    ...options
  }).then((response) => response.json())
);

const requestWithBody = ({params, url, ...options}) => (
  fetch(url, {
    body: JSON.stringify(params),
    headers: headers(),
    ...options
  }).then((response) => response.json())
);

export default (_options, otherOptions) => {
  let options = {..._options};

  if(otherOptions?.arg) {
    options = {...options, ...otherOptions.arg};
  }

  switch(options.method) {
    case "POST": return requestWithBody(options);
    case "PUT": return requestWithBody(options);
    default: return request(options);
  }
};
