import "@tomprats/font-awesome-pro/js/all";
import "core-js/stable";
import {useMemo, useReducer} from "react";
import {RouterProvider} from "react-router-dom";
import "regenerator-runtime/runtime";
import {SWRConfig} from "swr";
import Context from "app/context";
import Reducer from "app/reducer";
import fetcher from "app/requests/fetcher";
import createRouter from "app/router";
import useScrollbarWidth from "lib/hooks/use-scrollbar-width";

window.FontAwesome.config.autoReplaceSvg = "nest";

const createInitialState = (props) => ({
  app: {loading: false, ...props},
  notifications: [],
  pages: props.pages || [],
  sections: props.sections || [],
  user: props.user
});

export default function Application(props) {
  const [reducer, dispatch] = useReducer(Reducer, props, createInitialState);
  const state = useMemo(() => ({...reducer, dispatch}), [reducer, dispatch]);
  const router = useMemo(() => createRouter(state), [!!state.user]);

  useScrollbarWidth();

  return (
    <Context.Provider value={state}>
      <SWRConfig value={{fetcher}}>
        <RouterProvider router={router} />
      </SWRConfig>
    </Context.Provider>
  );
}
