import usePagination, {usePaginationContext} from "lib/hooks/use-pagination";

export default function Table() {
  const {records, total} = usePagination();
  const {Heading, Row} = usePaginationContext();

  return (
    <div className="table-responsive">
      <table className="table text-center">
        <thead>
          <Heading />
        </thead>
        <tbody>
          {records.map((record) => (
            <Row key={record.id} record={record} />
          ))}
          {records.length === 0 && (
            <tr className="text-center"><td colSpan="100%">Nothing found</td></tr>
          )}
          <tr className="text-center">
            <td colSpan="100%">{total} records</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
