export default function combineReducers(reducers) {
  return (state, action) => {
    const newState = {};

    Object.keys(reducers).forEach((key) => {
      newState[key] = reducers[key]({action, state: state[key]});
    });

    return newState;
  };
}
