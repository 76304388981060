import useSWRMutation from "swr/mutation";
import tomify from "app/assets/images/tomify.png";
import {createNotification} from "app/actions/notifications";
import NavLink from "components/helpers/nav-link";
import useAppContext from "lib/hooks/use-app-context";
import useContent from "lib/hooks/use-content";

const url = "/api/public/session";

export default function Navbar() {
  const {dispatch, pages, user} = useAppContext();
  const logo = useContent("Navbar", "Logo");
  const {trigger} = useSWRMutation({method: "DELETE", url});
  const onLogOut = () => {
    trigger().then((data) => {
      if(data.success) {
        window.location.href = `/?message=${data.message}&type=success`;
      } else {
        dispatch(createNotification({content: data.message, type: "error"}));
      }
    });
  };

  const defaultPages = pages.filter((page) => page.path !== "home");

  return (
    <nav className="navbar">
      <div className="container">
        <div className="flex items-center justify-between">
          <a className="inline-block" href="/">
            <img alt="Logo" src={logo || tomify} />
          </a>
          <div className="flex flex-wrap gap-4 justify-end">
            <NavLink to="/">Tomify</NavLink>
            {defaultPages.map((page) => (
              <NavLink key={page.path} to={`/${page.path}`}>{page.name}</NavLink>
            ))}
            {user ? (
              <>
                <NavLink to="/profile">Profile</NavLink>
                <button className="btn-nav" onClick={onLogOut} type="button">Log Out</button>
              </>
            ) : (
              <NavLink to="/session">Log In</NavLink>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
