import {useLayoutEffect} from "react";
import {useLocation, Outlet} from "react-router-dom";
import AdminNavbar from "components/layout/admin/navbar";
import Analytics from "components/layout/analytics";
import ErrorBoundary from "components/layout/error-boundary";
import Footer from "components/layout/footer";
import Notifications from "components/layout/notifications";
import Navbar from "components/layout/public/navbar";
import useAppContext from "lib/hooks/use-app-context";

export default function Layout() {
  const {user} = useAppContext();
  const location = useLocation();

  useLayoutEffect(() => { window.scrollTo(0, 0); }, [location.pathname]);

  return (
    <div className="flex flex-col h-screen">
      {user?.admin && <AdminNavbar />}
      <Navbar />
      <Notifications />
      <main>
        <ErrorBoundary><Outlet /></ErrorBoundary>
      </main>
      <Footer />
      <Analytics />
    </div>
  );
}
