import notifications from "app/reducers/notifications";
import page from "app/reducers/page";
import user from "app/reducers/user";
import combineReducers from "lib/combine-reducers";

const defaultReducer = ({state}) => state;

export default combineReducers({
  app: defaultReducer,
  notifications,
  page,
  pages: defaultReducer,
  sections: defaultReducer,
  user
});
