import Content from "components/helpers/action-text/content";
import useAppContext from "lib/hooks/use-app-context";

export default function Home() {
  const {page} = useAppContext();

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <div className="custom-html">
          <Content html={page.content} />
        </div>
      </div>
    </div>
  );
}
