import PropTypes from "prop-types";
import {Link} from "react-router-dom";

function Row({record}) {
  return (
    <tr key={record.id}>
      <td>{record.name}</td>
      <td>{record.contents.map(({name}) => name).join(", ")}</td>
      <td>
        <Link className="btn btn-sm btn-blue" to={`/admin/sections/${record.id}`}>Edit</Link>
      </td>
    </tr>
  );
}

Row.propTypes = {
  record: PropTypes.shape({
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default Row;
