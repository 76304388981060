import PropTypes from "prop-types";

function Notification({children = null, content = null, onClose = null, type = "success", ...props}) {
  let typeClasses = "bg-green text-light";
  if(type === "error") { typeClasses = "bg-red text-light"; }

  return (
    <div className={`border-light border-md mb-2 notification py-3 pl-4 pr-10 relative rounded-lg ${typeClasses}`} role="alert" {...props}>
      <div>{content || children}</div>
      {onClose && (
        <button aria-label="Close" className="absolute btn-icon inset-y-0 right-0 flex items-center mr-4" onClick={onClose} type="button">
          <i className="fas fa-times" />
        </button>
      )}
    </div>
  );
}

Notification.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string
};

export default Notification;
