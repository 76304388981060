import {useCallback, useState} from "react";
import {generatePath, useNavigate} from "react-router-dom";
import useSWRMutation from "swr/mutation";
import {createNotification} from "app/actions/notifications";
import {withoutBlankValues} from "lib/ext/object";
import {valueFrom, valueFromTarget} from "lib/form";
import useAppContext from "lib/hooks/use-app-context";
import usePrompt from "lib/hooks/use-prompt";

export default function useNew({
  defaults,
  mapSubmit,
  onCreate,
  path,
  withFiles = false,
  url
}) {
  const [block, setBlock] = useState(false);
  const [record, setRecord] = useState(defaults);
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const {trigger} = useSWRMutation({method: "POST", url});

  usePrompt({when: block});

  const onChange = useCallback(({target}) => {
    setBlock(true);
    setRecord((currentRecord) => ({
      ...currentRecord, [target.name]: valueFromTarget(target)
    }));
  }, []);

  const onChanges = useCallback((updatedRecord) => {
    setBlock(true);
    setRecord(updatedRecord);
  }, []);

  const onSubmit = useCallback((e) => {
    if(!withFiles) { e.preventDefault(); }

    const files = withFiles ? e : [];
    const updates = mapSubmit ? mapSubmit({files, record}) : {...record, ...files};
    const params = {record: withoutBlankValues(updates)};

    setBlock(false);
    trigger({params}).then(({message, record: createdRecord, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "error"}));
      if(!success) { return; }

      onCreate ? onCreate({record: createdRecord}) : navigate(generatePath(path, createdRecord));
    });
  }, [record, trigger]);

  const value = useCallback((name, defaultValue) => (
    valueFrom({defaultValue, name, objects: [record]})
  ), [record]);

  return {onChange, onChanges, onSubmit, record, value};
}
