import PropTypes from "prop-types";
import {useMemo} from "react";
import {Context} from "lib/hooks/use-pagination";
import Container from "./container";

function Paginate({search = false, ..._props}) {
  const props = useMemo(() => ({..._props, search}), [_props, search]);

  return (
    <Context.Provider value={props}>
      <Container />
    </Context.Provider>
  );
}

Paginate.propTypes = {
  Heading: PropTypes.elementType.isRequired,
  Row: PropTypes.elementType.isRequired,
  search: PropTypes.bool,
  url: PropTypes.string.isRequired
};

export default Paginate;
