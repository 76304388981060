import {useNew as useForm} from "lib/hooks/use-form";
import Form from "./form";

const defaults = {
  admin: false,
  email: "",
  first_name: "",
  last_name: "",
  notifications: true
};

export default function New() {
  const {onChange, onSubmit, value} = useForm({defaults, path: "/admin/users/:id", withFiles: true, url: "/api/admin/users"});

  return (
    <div className="container">
      <h1 className="text-center">Invite User</h1>
      <Form onChange={onChange} onSubmit={onSubmit} value={value} />
    </div>
  );
}
