import PropTypes from "prop-types";
import ContentEditor from "components/helpers/form/content-editor";
import Checkbox from "components/helpers/form/checkbox";
import File from "components/helpers/form/file";
import {valueFromTarget} from "lib/form";

// TODO: Date - onChange + onSubmit?
// TODO: File - Displaying non-image files
function Content({onChange: setChanges, record}) {
  const id = `record-value-${record.id}`;
  const onChange = ({target}) => (
    setChanges({...record, value: valueFromTarget(target)})
  );
  const onDateChange = () => {};

  switch(record.kind) {
    case "Boolean":
      return (
        <div className="mb-4">
          <Checkbox
            checked={record.value || false}
            id={id}
            label={record.name}
            name="value"
            onChange={onChange}
          />
        </div>
      );
    case "Date":
      return (
        <div className="mb-4">
          <label htmlFor={id}>{record.name}</label>
          <input
            id={id}
            name="value"
            onChange={onDateChange}
            type="datetime-local"
            value={record.value || ""}
          />
        </div>
      );
    case "Email":
      return (
        <div className="mb-4">
          <label htmlFor={id}>{record.name}</label>
          <input
            id={id}
            name="value"
            onChange={onChange}
            placeholder={record.name}
            type="email"
            value={record.value || ""}
          />
        </div>
      );
    case "File":
      return (
        <div className="mb-4">
          <label htmlFor={id}>{record.name}</label>
          <File id={id} name={record.id} onChange={onChange} />
          {record.value && <img alt="Preview" src={record.value} />}
        </div>
      );
    case "HTML":
      return (
        <div className="mb-4">
          <label htmlFor={`${id}-disabled`}>{record.name}</label>
          <ContentEditor
            id={id}
            name="value"
            onChange={onChange}
            value={record.value}
          />
        </div>
      );
    default:
      return (
        <div className="mb-4">
          <label htmlFor={id}>{record.name}</label>
          <input
            id={id}
            name="value"
            onChange={onChange}
            placeholder={record.name}
            type="text"
            value={record.value || ""}
          />
        </div>
      );
  }
}

Content.propTypes = {
  onChange: PropTypes.func.isRequired,
  record: PropTypes.shape({
    kind: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any // eslint-disable-line react/forbid-prop-types
  }).isRequired
};

export default Content;
