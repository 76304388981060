import {useState} from "react";
import useSWRMutation from "swr/mutation";
import {createNotification} from "app/actions/notifications";
import useAppContext from "lib/hooks/use-app-context";
import useQueryParams from "lib/hooks/use-query-params";

const url = "/api/public/forgot_password";

export default function ForgotPassword() {
  const {dispatch} = useAppContext();
  const params = useQueryParams();
  const [email, setEmail] = useState(params.get("email") || "");
  const {trigger} = useSWRMutation({method: "POST", params: {email}, url});
  const onSubmit = (e) => {
    e.preventDefault();

    trigger().then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));
    });
  };

  return (
    <div className="container">
      <h1 className="text-center">Forgot Password</h1>
      <div className="max-w-sm mx-auto">
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <input
              id="session-email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
              required={true}
              type="email"
              value={email}
            />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-theme px-5">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
