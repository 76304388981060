import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {withoutBlankValues} from "lib/ext/object";
import usePagination from "lib/hooks/use-pagination";

export default function Search() {
  const {query} = usePagination();
  const [search, setSearch] = useState(query.search || "");
  const setSearchParams = useSearchParams()[1];

  useEffect(() => { setSearch(query.search || ""); }, [query.search]);

  const onSearch = (e) => {
    e.preventDefault();

    setSearchParams(withoutBlankValues({...query, search}));
  };

  return (
    <form className="gap-2 flex items-center" onSubmit={onSearch}>
      <input
        className="grow md:grow-0 w-auto"
        name="value"
        onChange={({target: {value}}) => setSearch(value)}
        placeholder="Search"
        type="search"
        value={search || ""}
      />
      <button className="btn btn-blue" type="submit">Search</button>
    </form>
  );
}
