import useSWRMutation from "swr/mutation";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import useAppContext from "lib/hooks/use-app-context";

const url = "/api/public/user";

export default function Notifications() {
  const {dispatch, user} = useAppContext();
  const {trigger} = useSWRMutation({method: "PUT", url});

  const onSubscribe = () => {
    trigger({params: {record: {notifications: true}}}).then((data) => {
      if(data.success) {
        dispatch(setUser(data.record));
        dispatch(createNotification({content: "Successfully Subscribed", type: "success"}));
      } else {
        dispatch(createNotification({content: data.message, type: "error"}));
      }
    });
  };
  const onUnsubscribe = () => {
    trigger({params: {record: {notifications: false}}}).then((data) => {
      if(data.success) {
        dispatch(setUser(data.record));
        dispatch(createNotification({content: "Successfully Unsubscribed", type: "error"}));
      } else {
        dispatch(createNotification({content: data.message, type: "error"}));
      }
    });
  };

  return (
    <div className="container">
      <h1 className="text-center">Email Notifications</h1>
      <p className="mb-2 text-center">This won't affect transactional emails (such as password resets)</p>
      <div className="text-center">
        {user.notifications ? (
          <button className="btn btn-red px-5" onClick={onUnsubscribe} type="button">Unsubscribe</button>
        ) : (
          <button className="btn btn-green px-5" onClick={onSubscribe} type="button">Subscribe</button>
        )}
      </div>
    </div>
  );
}
