import {v4 as createUUID} from "uuid";
import {last} from "lib/ext/array";
import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION
} from "../actions/notifications";

export default function notifications({action, state = []}) {
  switch(action.type) {
    case CREATE_NOTIFICATION: {
      const {content, id, type} = action.notification;

      return [
        ...state,
        {
          content: typeof content === "object" ? (
            Object.keys(content).map((key) => (
              content[key].map((value) => `${last(key.split("."))} ${value}`).join(", ")
            )).join(", ")
          ) : content,
          id: id || createUUID(),
          type: type || "success"
        }
      ];
    }
    case DELETE_NOTIFICATION:
      return state.filter(({id}) => id !== action.notification.id);
    default:
      return state;
  }
}
