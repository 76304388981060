import {Link} from "react-router-dom";
import Paginate from "components/helpers/admin/paginate";
import Row from "./row";

function Heading() {
  return (
    <tr>
      <th>Admin</th>
      <th>Email</th>
      <th>Name</th>
      <th>Created At</th>
      <th>Actions</th>
    </tr>
  );
}

export default function AdminUsersList() {
  return (
    <div className="container">
      <div className="max-w-screen-lg mx-auto">
        <h1 className="text-center">User</h1>
        <div className="text-right mb-1">
          <Link className="btn btn-outline-black btn-sm" to="/admin/users/new">Invite User</Link>
        </div>
        <Paginate search={true} url="/api/admin/users" Heading={Heading} Row={Row} />
      </div>
    </div>
  );
}
