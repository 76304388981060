import {unstable_usePrompt as useBasePrompt} from "react-router-dom";
import useAppContext from "lib/hooks/use-app-context";

export default function usePrompt({message: _message, when}) {
  const {app: {environment}} = useAppContext();
  const development = environment !== "production";
  const message = _message || "Are you sure you want to leave without saving?";

  useBasePrompt({
    message,
    when: () => {
      if(development) { return false; }

      return when;
    }
  });
}
