import {useCallback} from "react";
import useSWRMutation from "swr/mutation";
import {createNotification} from "app/actions/notifications";
import useAppContext from "lib/hooks/use-app-context";
import usePagination, {usePaginationContext} from "lib/hooks/use-pagination";

export default function useDelete({check, record}) {
  const {dispatch} = useAppContext();
  const {mutate} = usePagination();
  const {url} = usePaginationContext();
  const {trigger} = useSWRMutation({method: "DELETE", url: `${url}/${record.id}`});

  return useCallback(() => {
    if(check && !window.confirm(check)) { return; }

    trigger().then(({message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "error"}));

      if(!success) { return; }

      mutate((data) => ({...data, records: data.records.filter(({id}) => id !== record.id)}));
    });
  }, [check, record, trigger]);
}
